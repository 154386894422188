import { createMuiTheme } from '@material-ui/core';
import { Colors } from './colors';

const theme = createMuiTheme({
  palette: {
    background: {
      paper: Colors.white,
      default: Colors.grey['50'],
    },
    grey: Colors.grey,
  },
  typography: {
    fontSize: 16,
    htmlFontSize: 16,
    fontFamily: ['CircularStd', 'sans-serif'].join(','),
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: '1rem',
        border: 'none',
        color: Colors.grey['500'],
        '&$focused': {
          color: Colors.grey['500'],
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        color: Colors.grey['300'],
      },
    },
    MuiLinearProgress: {
      colorPrimary: { backgroundColor: Colors.brandColor.primary.medium },
      barColorPrimary: { backgroundColor: '#ddf7f0' },
    },
    MuiCircularProgress: {
      colorPrimary: { color: Colors.brandColor.primary.dark },
    },
    MuiTypography: {
      root: { fontSize: '1rem' },
      body1: { fontSize: '1rem' },
      noWrap: {
        overflow: 'unset',
      },
      colorTextPrimary: {
        color: Colors.brandColor.secondary,
      },
      h1: {
        fontSize: '2rem',
      },
      h2: {
        fontSize: '1.5rem',
      },
      h3: {
        fontSize: '1rem',
      },
      h4: {
        fontSize: '0.875rem',
      },
      h5: {
        fontSize: '0.75rem',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '0.875rem',
        borderBottom: `2px solid ${Colors.grey['50']}`,
      },
    },
    MuiCheckbox: {
      root: { color: Colors.grey['300'], borderRadius: 0 },
      colorSecondary: {
        '&$checked': {
          color: Colors.brandColor.primary.dark,
        },
      },
    },
    MuiTablePagination: {
      root: {
        fontSize: '0.75rem',
        color: Colors.grey['500'],
        paddingRight: '24px',
      },
      caption: {
        fontSize: '0.75rem',
        color: Colors.grey['500'],
      },
      selectIcon: {
        fill: 'none',
        border: `solid ${Colors.grey['500']}`,
        borderWidth: '0 2px 2px 0',
        transform: 'translateX(-6px) translateY(6px) rotate(45deg)',
        width: '5px',
        height: '5px',
      },
      menuItem: {
        fontSize: '0.75rem',
        color: Colors.grey['500'],
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiInput: {
      root: {
        width: '100%',
        minHeight: '48px',
        borderRadius: '8px',
        border: `solid 1px ${Colors.grey['200']}`,
        backgroundColor: Colors.white,
        padding: '12px 16px',
        color: Colors.grey['500'],
        '&$disabled': {
          backgroundColor: Colors.grey['100'],
        },
      },
    },
    MuiInputBase: {
      input: {
        padding: 0,
      },
    },
    MuiInputLabel: {
      root: {
        marginBottom: '4px',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
      },
      contained: {
        backgroundColor: Colors.brandColor.primary.dark,
        borderRadius: '25px',
        minWidth: '168px',
        height: '50px',
        padding: '0 25px',
        color: Colors.white,
        '&:hover': {
          backgroundColor: Colors.brandColor.primary.dark,
        },
      },
      text: {
        color: Colors.grey['300'],
        borderBottom: `1px solid ${Colors.grey['300']}`,
        height: '2em',
        backgroundColor: 'none',
        '&:hover': {
          color: Colors.brandColor.secondary,
          borderBottom: `1px solid ${Colors.brandColor.secondary}`,
          backgroundColor: 'none',
          boxShadow: 'none',
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: Colors.grey['300'],
        '&$checked': {
          color: Colors.brandColor.primary.dark,
        },
      },
    },
    MuiMenuItem: {
      root: {
        color: Colors.grey['500'],
      },
    },
  },
});

export default theme;
