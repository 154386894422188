import { FilesState, FilesTypes } from './types';
import { Reducer } from 'redux';
import reducerProgressFile from './progressUploadReducer';

const INITIAL_STATE: FilesState = {
  current: {
    id: '',
    title: '',
    description: '',
    status: 0,
  },
};

const files: Reducer<FilesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FilesTypes.ADD_CURRENT_FILE:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};

export { reducerProgressFile, files };
