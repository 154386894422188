import { SDebug } from './s-debug';

export class FetchParams {
  public url = '';
  public data?: any | object;
  public query?: any | object;
  public multipart?: boolean = false;
  public headers?: any | object;
}

export class SFetch {
  public static SERVER_API?: string;
  public static AUTHORIZATION: string;

  public static async get(params: FetchParams) {
    const method = 'GET';
    const uri = SFetch.getURI(params, method);

    const header = {
      method: method,
      headers: SFetch.getHeaders(params),
    };

    return await SFetch.extractResponse(uri, header, method);
  }

  public static async delete(params: FetchParams) {
    const method = 'DELETE';
    const uri = SFetch.getURI(params, method);

    const header = {
      method: method,
      headers: SFetch.getHeaders(params),
    };

    return await SFetch.extractResponse(uri, header, method);
  }

  public static async post(params: FetchParams) {
    const method = 'POST';
    const uri = SFetch.getURI(params, method);

    const header = {
      method: method,
      headers: SFetch.getHeaders(params),
      body: SFetch.getBody(params),
    };

    return await SFetch.extractResponse(uri, header, method);
  }

  public static async put(params: FetchParams) {
    const method = 'PUT';
    const uri = SFetch.getURI(params, method);

    const header = {
      method: method,
      headers: SFetch.getHeaders(params),
      body: SFetch.getBody(params),
    };

    return await SFetch.extractResponse(uri, header, method);
  }

  public static getHeaders(params: FetchParams) {
    let headers: any = {
      Accept: 'application/json',
      Authorization: SFetch.AUTHORIZATION,
    };

    if (!params.multipart) {
      headers['Content-Type'] = 'application/json';
    }

    if (params.headers) {
      headers = params.headers;
    }

    return headers;
  }

  public static getBody(params: FetchParams) {
    return params.multipart ? params.data : JSON.stringify(params.data);
  }

  private static getURI(params: FetchParams, method: string) {
    const uri = `${SFetch.SERVER_API}/${params.url}${SFetch.buildQueryString(
      params,
    )}`;

    params.url = uri;
    SDebug.info(params, method);
    return uri;
  }

  private static async extractResponse(
    uri: string,
    header: any,
    method: string,
  ) {
    try {
      const response = await fetch(uri, header);
      if (response.ok) {
        if (
          response?.headers?.get('content-type')?.match(/application\/json/)
        ) {
          return await response.json();
        } else {
          return null;
        }
      } else {
        return SFetch.onResponseNotOkay(response, method);
      }
    } catch (error) {
      SDebug.warn(error, method);
      throw error;
    }
  }

  private static async onResponseNotOkay(response: Response, method: string) {
    const text = await response.text();
    const error = JSON.parse(text);

    SDebug.warn({ error, text }, method);
    throw error;
  }

  private static buildQueryString({ query }: FetchParams) {
    let queryString = '';
    if (query) {
      queryString = Object.keys(query)
        .reduce((acc: string[], cur) => {
          return [
            ...acc,
            `${cur}=${encodeURIComponent(JSON.stringify(query[cur]))}`,
          ];
        }, [])
        .join('&');
      if (queryString) {
        queryString = `?${queryString}`;
      }
    }
    SDebug.info(`Query String: ${queryString}`);
    return queryString;
  }
}
