/**
 * Action types
 */
export enum NotificationTypes {
  ENQUEUE = '@notification/ENQUEUE',
  REMOVE = '@notification/REMOVE',
}

/** Data types */
export interface Notification {
  key: string;
  message: string;
  options?: any;
}

/**
 * State types
 */
export interface NotificationState {
  notifications: Notification[];
}
