import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider } from '@material-ui/core';

import Routes from './routes';
import CustomSnackbarProvider from 'components/notification/CustomSnackbarProvider';

import store, { persistor } from 'store';

import theme from 'styles/theme';

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomSnackbarProvider>
          <Routes />
        </CustomSnackbarProvider>
      </PersistGate>
    </Provider>
  </MuiThemeProvider>
);

export default App;
