import { combineReducers } from 'redux';

import notification from './notification';
import { files, reducerProgressFile } from './files';
import audiobooks from './audiobooks';
import journeys from './journeys';

export default combineReducers({
  notification,
  files,
  audiobooks,
  journeys,
  reducerProgressFile,
});
