import { createStore, Store, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReactotronConfig from 'ReactotronConfig';

import rootReducer from './ducks/rootReducer';

import { AppState } from './types';

const persisteReducer = persistReducer(
  {
    key: 'peregrino',
    storage,
    whitelist: ['auth'],
  },
  rootReducer,
);

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? ReactotronConfig.createSagaMonitor!()
    : undefined;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const enhancer =
  process.env.NODE_ENV === 'development'
    ? compose(
        ReactotronConfig.createEnhancer!(),
        applyMiddleware(sagaMiddleware),
      )
    : applyMiddleware(sagaMiddleware);

const store: Store<AppState> = createStore(persisteReducer, enhancer);

export const persistor = persistStore(store);
export default store;
