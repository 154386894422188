export enum SDebugLevel {
  log,
  info,
  warn,
  error,
  silent,
}

export class SDebug {
  private static debugLevel: SDebugLevel = SDebugLevel.log;

  private static console = console;

  public static setDebugLevel(level: SDebugLevel) {
    this.debugLevel = level;
  }

  public static getDebugLevel() {
    return this.debugLevel;
  }

  public static log(message?: any, ...optionalParams: any[]): void {
    if (SDebug.debugLevel <= SDebugLevel.log) {
      SDebug.console.log(message, ...optionalParams);
    }
  }

  public static info(message?: any, ...optionalParams: any[]): void {
    if (SDebug.debugLevel <= SDebugLevel.info) {
      SDebug.console.info(message, ...optionalParams);
    }
  }

  public static warn(message?: any, ...optionalParams: any[]): void {
    if (SDebug.debugLevel <= SDebugLevel.warn) {
      SDebug.console.warn(message, ...optionalParams);
    }
  }

  public static error(message?: any, ...optionalParams: any[]): void {
    if (SDebug.debugLevel <= SDebugLevel.error) {
      SDebug.console.error(message, ...optionalParams);
    }
  }

  public static trace(message?: any, ...optionalParams: any[]): void {
    if (SDebug.debugLevel <= SDebugLevel.info) {
      SDebug.console.trace(message, ...optionalParams);
    }
  }

  public static debug(message?: any, ...optionalParams: any[]): void {
    if (SDebug.debugLevel <= SDebugLevel.info) {
      SDebug.console.debug(message, ...optionalParams);
    }
  }

  public static table(message?: any, ...optionalParams: any[]): void {
    if (SDebug.debugLevel <= SDebugLevel.info) {
      SDebug.console.table(message, ...optionalParams);
    }
  }
}
