import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

const AdminRouter = lazy(() => import('./AdminRouter'));
const ClientRouter = lazy(() => import('./ClientRouter'));

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Switch>
          <Route path="/admin">
            <AdminRouter />
          </Route>
          <Route path="/">
            <ClientRouter />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
