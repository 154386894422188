import { FilesTypes, FileProgressState } from './types';
import { Reducer } from 'redux';

const INITIAL_STATE: FileProgressState = {
  current: {
    index: 0,
    progress: 0,
  },
};

const reducerProgressFile: Reducer<FileProgressState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case FilesTypes.ADD_PROGRESS_FILE:
      return {
        current: {
          index: action.payload.index,
          progress: action.payload.progress,
        },
      };

    default:
      return state;
  }
};

export default reducerProgressFile;
