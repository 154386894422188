import { NotificationState, NotificationTypes } from './types';
import { Reducer } from 'redux';

const INITIAL_STATE: NotificationState = {
  notifications: [],
};

const reducer: Reducer<NotificationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NotificationTypes.ENQUEUE:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case NotificationTypes.REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(
          n => n.key !== action.payload.key,
        ),
      };
    default:
      return state;
  }
};

export default reducer;
