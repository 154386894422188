/**
 * Action types
 */
export enum AudiobooksTypes {
  ADD_CURRENT_AUDIOBOOK = '@audiobooks/ADD_CURRENT_AUDIOBOOK',
  SET_CURRENT_AUDIOBOOK_GROUP = '@audiobooks/SET_CURRENT_AUDIOBOOK_GROUP',
}

/** Data types */
export type Audiobook = {
  id: string;
  status?: number;
  title?: string;
  description?: string;
  author?: string;
  genre?: string;
  year?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  imageUrl?: string;
  filters?: string[];
};

export type AudiobookGroup = {
  id: string;
  status?: number;
  title?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  imageUrl?: string;
  filters?: string[];
};

/**
 * State types
 */
export interface AudiobooksState {
  current: Audiobook;
  currentGroup: AudiobookGroup;
}
