import { action } from 'typesafe-actions';
import { NotificationTypes } from './types';
import { SnackbarKey } from 'notistack';

export const notifyClose = (key: SnackbarKey) =>
  action(NotificationTypes.REMOVE, { key });

export const notifyError = (message: string) =>
  action(NotificationTypes.ENQUEUE, {
    message,
    key: new Date().getTime() + Math.random(),
    options: {
      variant: 'error',
    },
  });

export const notifySuccess = (message: string) =>
  action(NotificationTypes.ENQUEUE, {
    message,
    key: new Date().getTime() + Math.random(),
    options: {
      variant: 'success',
    },
  });
