import { JourneysState, JourneysTypes } from './types';
import { Reducer } from 'redux';

const INITIAL_STATE: JourneysState = {
  current: {
    id: '',
    title: '',
    description: '',
    status: 0,
  },
  currentGroup: {
    id: '',
    title: '',
    status: 0,
  },
};

const reducer: Reducer<JourneysState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case JourneysTypes.SET_CURRENT_JOURNEY:
      return {
        ...state,
        current: action.payload,
      };
    case JourneysTypes.SET_CURRENT_JOURNEY_GROUP:
      return {
        ...state,
        currentGroup: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
