import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { SFetch } from './common/s-fetch';
import { AuthService } from './services/auth-service';

SFetch.SERVER_API = process.env.REACT_APP_API_URL;
SFetch.AUTHORIZATION = `Bearer ${AuthService.getToken()}`;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
