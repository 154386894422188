import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Notification } from 'store/ducks/notification/types';
import { notifyClose } from 'store/ducks/notification/actions';
import { AppState } from 'store/types';

const Notifier = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const notifications = useSelector<AppState, Notification[]>(
    ({ notification }: AppState) => notification.notifications || [],
  );

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {} }) => {
      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onExited: (_, k) => {
          dispatch(notifyClose(k));
        },
      });
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
