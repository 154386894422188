import { makeStyles } from '@material-ui/core';

import Colors from 'styles/colors';

export const useStyles = makeStyles({
  closeButton: {
    cursor: 'pointer',
  },
  root: {
    '& .MuiSnackbarContent-root': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '18px 16px',
      backgroundColor: `${Colors.brandColor.secondary} !important`,
      color: Colors.white,
      width: '456px',
      height: 'min-content',
      borderRadius: '8px',
      boxShadow: `0 2px 8px -2px ${Colors.boxShadow}`,
    },
    '& .MuiSnackbarContent-message': {
      width: '80%',
      padding: 0,
      fontSize: '14px',
      '& svg': {
        width: 32,
        minWidth: 32,
        marginRight: 16,
      },
    },
    '& .MuiSnackbarContent-action': {
      marginRight: 0,
    },
  },
});
