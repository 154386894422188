export const Colors = {
  white: '#FFFFFF',
  red: '#eb5757',
  boxShadow: 'rgba(171, 167, 167, 0.2)',
  grey: {
    '50': '#F8F9FB',
    '100': '#EAE9E9',
    '200': '#E0DEDE',
    '300': '#CBC8C8',
    '400': '#ABA7A7',
    '500': '#827C7C',
    '600': '#625A5A',
    '700': '#4D4444',
    '800': '#433A3A',
    '900': '#2C2828',
  },
  brandColor: {
    primary: {
      light: '#4EE9BF',
      medium: '#53D9B5',
      dark: '#3DBF9C',
      bright: '#00f6dd',
    },
    secondary: '#2E2424',
  },
};

export default Colors;
