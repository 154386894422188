import React, { MutableRefObject, useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { Typography } from '@material-ui/core';

import Notifier from 'components/notification/Notifier';

import { ReactComponent as CloseIcon } from 'assets/default-icons/icon_default_close.svg';
import { ReactComponent as ErrorIcon } from 'assets/default-icons/icon_error.svg';
import { ReactComponent as SuccessIcon } from 'assets/default-icons/icon_success.svg';

import { useStyles } from './styles';

import { CustomSnackbarPropsType } from './types';

const CustomSnackbarProvider = ({ children }: CustomSnackbarPropsType) => {
  const classes = useStyles();
  const notistackRef = useRef() as MutableRefObject<React.FC>;

  const onClickClose = (key: string | number) => () =>
    (notistackRef as any)?.current?.closeSnackbar(key);

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={1}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      iconVariant={{
        error: <ErrorIcon />,
        success: <SuccessIcon />,
      }}
      action={key => (
        <CloseIcon
          onClick={onClickClose(key)}
          className={classes.closeButton}
        />
      )}
      classes={{
        root: classes.root,
      }}
    >
      <Typography variant="h4">{children}</Typography>
      <Notifier />
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
