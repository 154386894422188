import { AudiobooksState, AudiobooksTypes } from './types';
import { Reducer } from 'redux';

const INITIAL_STATE: AudiobooksState = {
  current: {
    id: '',
    title: '',
    description: '',
    status: 0,
  },
  currentGroup: {
    id: '',
    title: '',
    status: 0,
  },
};

const reducer: Reducer<AudiobooksState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AudiobooksTypes.ADD_CURRENT_AUDIOBOOK:
      return {
        ...state,
        current: action.payload,
      };
    case AudiobooksTypes.SET_CURRENT_AUDIOBOOK_GROUP:
      return {
        ...state,
        currentGroup: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
