/**
 * Action types
 */
export enum FilesTypes {
  ADD_CURRENT_FILE = '@files/ADD_CURRENT_FILE',
  ADD_PROGRESS_FILE = '@files/ADD_PROGRESS_FILE',
}

/** Data types */
export type File = {
  id: string;
  status?: number;
  title?: string;
  duration?: number;
  description?: string;
  fileName?: string;
  audioUrl?: string;
};

/**
 * State types
 */
export interface FilesState {
  current: File;
}
export interface FileProgressState {
  current: { index: number; progress: number };
}
