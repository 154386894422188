/**
 * Action types
 */
export enum JourneysTypes {
  SET_CURRENT_JOURNEY = '@journeys/SET_CURRENT_JOURNEY',
  SET_CURRENT_JOURNEY_GROUP = '@journeys/SET_CURRENT_JOURNEY_GROUP',
}

/** Data types */
export type Journey = {
  id: string;
  status?: number;
  title?: string;
  description?: string;
  recurrence?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  imageUrl?: string;
};

export type JourneyGroup = {
  id: string;
  status?: number;
  title?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  imageUrl?: string;
};

/**
 * State types
 */
export interface JourneysState {
  current: Journey;
  currentGroup: JourneyGroup;
}
