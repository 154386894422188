import { SFetch } from 'common/s-fetch';

export const TOKEN_KEY = 'jwtToken';
export const CURRENT_USER = 'currentUser';

export class AuthService {
  public static isAuthenticated() {
    return localStorage.getItem(TOKEN_KEY) !== null;
  }

  public static login(user: any) {
    localStorage.setItem(TOKEN_KEY, user.accessToken);
    localStorage.setItem(CURRENT_USER, JSON.stringify(user));
    SFetch.AUTHORIZATION = `Bearer ${user.accessToken}`;
  }

  public static logout() {
    SFetch.post({ url: 'auth/signout' });
    localStorage.clear();
  }

  public static getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  public static setCurrentUser(user: object) {
    localStorage.setItem(CURRENT_USER, JSON.stringify(user));
  }

  public static getCurrentUser() {
    return JSON.parse(localStorage.getItem(CURRENT_USER) || '{}');
  }
}
